import React, { useState } from 'react';
import cn from 'classnames';
import './SortButton.css';

const nextState = current => !current ? 'up' : current === 'up' ? 'down' : null;
const sort = (list, order, fn) =>
  order === 'up'
    ? list.slice().sort(fn)
    : order === 'down'
    ? list.slice().sort((a, b) => fn(b, a))
    : list;

const SortButton = ({ label, list, sortFn, onSorted }) => {
  const [order, setOrder] = useState(null);

  return (
    <div
      className='sort-button'
      onClick={() => {
        const newState = nextState(order);
        onSorted(sort(list, newState, sortFn));
        setOrder(newState);
      }}
    >
      {label}
      <svg className='sort-icon' width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
        <path
          className={cn(order !== 'up' && 'icon-secondary')}
          d='M6.66602 8.66732L9.99935 5.33398L13.3327 8.66732H6.66602Z'
          fill='currentColor'
        />
        <path
          className={cn(order !== 'down' && 'icon-secondary')}
          d='M6.66602 12.5L9.99935 15.8333L13.3327 12.5H6.66602Z'
          fill='currentColor'
        />
      </svg>
    </div>
  );
};

export default SortButton;