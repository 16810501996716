import React from 'react';
import cn from 'classnames';
import './Tile.css';

const Tile = ({ name, iconUrl, onClick, disabled }) => (
  <div
    className={cn('tile', disabled && 'disabled')}
    onClick={disabled ? undefined : onClick}
  >
    <img className='icon' src={iconUrl} alt={name} />
    <h3 className='name' title={name}>{name}</h3>
  </div>
);

export default Tile;
