export default function generateSchemaName(service, connectors) {
  const existingSchemas = connectors.map(item => item.schema.split('.')[0]);
  const defaultSchemaName = service + '_pbf_sample';
  let counter = 1;
  let schemaName = defaultSchemaName;

  while(existingSchemas.includes(schemaName))
    schemaName = defaultSchemaName + counter++;

  return schemaName;
}
