import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import './Button.css'

const Button = ({ children, type, onClick, href, className }) =>
  href ? (
    <Link className={cn('button', type, className)} to={href}>
      {children}
    </Link>
  ) : (
    <div className={cn('button', type, className)} onClick={onClick}>
      {children}
    </div>
  );

export default Button;
