import React from 'react';
import './Tab.css';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

const Tab = ({ className, title, Icon, link }) => (
  <NavLink to={link} className={cn(className, 'tab-link')} activeClassName='active'>
    <Icon />
    {title}
  </NavLink>
);

export default Tab;
    
