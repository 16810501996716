export default function getConnectorsTableData(connectors, services) {
  return connectors
    .map(connector => {
      const service = services.find(s => s.id === connector.service);

      if (!service) return null;

      return {
        id: connector.id,
        iconUrl: service.icon_url,
        serviceName: service.name,
        connectorName: connector.display_name,
        status: connector.status
      };
    })
    // NOTE: We need to filter connectors in group that could
    // be unavailable via API (no service metadata provided)
    .filter(c => !!c);
}
