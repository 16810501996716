import {React,  useState, useRef } from 'react';
import './Login.css';
import PropTypes from 'prop-types';
import { post } from '../request';
import Button from './Button/Button';
import Input from './Input/Input';
import logo from '../images/logo.svg';
import { LoadingIndicator } from '../components/LoadingIndicator';

export default function Login({ setToken }) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState('');
    const formRef = useRef(null);
    const[isLoading, setIsLoading] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        try{
            setIsLoading(true);
            const token = await post('/_/login', { email, password });
            setError('');
            setToken(token);
            setIsLoading(false);
        } catch(err) {
            setError('Wrong email or password, please try again');
            setEmail('');
            setPassword('');
            setIsLoading(false);
        }
    }
    return isLoading ? <LoadingIndicator/> : (
        <div className='login-overlay'>
            <div className='login-wrapper'>
                <div className='login-logo'>
                    POWERED BY
                    <img src={logo} alt='Fivetran'/>
                </div>
                <div className='login-title'>Sign in to launch the demo</div>
                {error && <div className='login-error-text'>{error}</div>}
                <form onSubmit={handleSubmit} ref={formRef} className='login-form'>
                    <Input onChange={setEmail} value={email} placeholder='Email' className='login-input' />
                    <Input type='password' onChange={setPassword} value={password} placeholder='Password' className='login-input' />
                    <Button
                        type='primary'
                        className='login-button'
                        onClick={() => {
                            formRef.current.requestSubmit();
                        }}
                    >
                        Sign In
                    </Button>
                </form>

                <div className='login-title'>Helpful text with <a href='https://fivetran.com' target='_blank' rel='noreferrer'>link</a></div>
            </div>
        </div>
    )
}
Login.propTypes = {
    setToken: PropTypes.func.isRequired
}