import React, { useState } from 'react';
import { post } from '../../request';
import { LoadingIndicator } from '../LoadingIndicator';
import './Services.css';
import Tile from './Tile'
import MainLayout from '../MainLayout/MainLayout';
import generateSchemaName from './generateSchemaName';
import ErrorMessage from '../ErrorMessage';

const Services = ({ services, connectors, token }) => {
  const[isLoading, setIsLoading] = useState(false);
  const[error, setError] = useState(null);

  const onServiceClick = serviceId => {
    setIsLoading(true);
    post('/_/connectors', {
      service: serviceId,
      name: generateSchemaName(serviceId, connectors)
    }, token)
      .then(connector => {
        window.location.href = `${connector.url}&redirect_uri=${window.location.origin}/connectors/`;
      }, setError);
  }

  if (error)
    return <ErrorMessage error={error} />

  if (isLoading)
    return <LoadingIndicator/>

  return (
    <MainLayout>
      <div className='services'>
        {services.map(({ id, name, icon_url }) => (
          <Tile
            key={id}
            name={name}
            iconUrl={icon_url}
            onClick={() => onServiceClick(id)}
            disabled={!!connectors.find(c => c.service === id)}
          />
        ))}
      </div>
    </MainLayout>
  );
}

export default Services;
