import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { Connectors } from './components/Connectors/Connectors';
import Services from "./components/Services/Services";
import  Login  from './components/Login';
import { checkAuth, get } from './request';
import { LoadingIndicator } from "./components/LoadingIndicator";
import useToken from './components/useToken';
import ErrorMessage from './components/ErrorMessage';
import './PBF-App.css'


export default function App () {
  const [services, setServices] = useState(null);
  const [connectors, setConnectors] = useState(null);
  const [error, setError] = useState(null);
  const {token, setToken} = useToken();
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateConnectors, setUpdateConnectors] = useState(false);
  
  useEffect(() => {
    const load = async () => {
      setLoading(true)
      try {
        setAuthorized(await checkAuth(token))
        if (authorized) {
          setConnectors(await get('/_/connectors', token));
          if (!updateConnectors){
            setServices(await get('/_/services', token));
          }
          setError(null);
        }
      } catch (e) {
        setError(e);
      }
      setLoading(false)
      setUpdateConnectors(false)
    }
    load();
  }, [token, authorized, updateConnectors]);

  if (loading)
    return <LoadingIndicator />;

  if (error)
    return <ErrorMessage error={error} />;

  if (!authorized)
    return <Login setToken={setToken} />;
  
  else if (!services || !connectors)
    return <LoadingIndicator />;

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          {authorized ? <Redirect to="/services" /> : <Login setToken={setToken} />}
        </Route>
        <Route path="/connectors">
          <Connectors services={services} connectors={connectors} token={token} updateConnectors={setUpdateConnectors}/>
        </Route>
        <Route path="/services">
          <Services services={services} connectors={connectors} token={token}/>
        </Route>
        <Redirect from="/" to="/services" />
      </Switch>
    </BrowserRouter>
  );
}
