import React from 'react';
import cn from 'classnames';
import './TabsRow.css';
import Tab from './Tab';

const TabsRow = ({ className, tabs }) => (
  <div className={cn(className, 'tabs-row')}>
    { 
      tabs.map((tabProps, i) => (
        <Tab key={i.toString()} className='tabs-margin' {...tabProps} />
      ))
    }
  </div>
);

export default TabsRow;
