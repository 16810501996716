import React from 'react';
import cn from 'classnames';
import './Input.css'

const Input = ({
  className,
  type,
  value,
  placeholder,
  onChange
}) =>(
    <input
      className={cn('input', className)}
      type={type ?? 'text'}
      value={value}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
    />
  );

export default Input;
