import React, { useState } from 'react';
import './ConnectorsTable.css'
import SortButton from './SortButton';
import NoConnectors from './NoConnectors';
import StatusBadge from './StatusBadge';

const ConnectorsTable = ({ connectors, onDelete }) => {
  const [sortedConnectors, setSortedConnectors] = useState(connectors);

  if (!connectors.length) return <NoConnectors />

  return (
    <table className='connectors-table'>
      <tbody>
        <tr className='header-row'>
          <th>
            <SortButton
              label='Connector Name'
              list={connectors}
              sortFn={(c1, c2) => c1.serviceName.localeCompare(c2.serviceName)}
              onSorted={setSortedConnectors}
            />
          </th>
          <th>
            <SortButton
              label='Status'
              list={connectors}
              sortFn={(c1, c2) => c1.status.sync_state.localeCompare(c2.status.sync_state)}
              onSorted={setSortedConnectors}
            />
          </th>
          <th>Manage</th>
        </tr>
        {sortedConnectors.map(({ id, iconUrl, serviceName, status }) => (
          <tr key={id} className='data-row'>
            <td>
              <div className='vertical-align-center connector-name'>
                <div className='logo-box'>
                  <img src={iconUrl} alt={serviceName} />
                </div>
                {serviceName}
              </div>
            </td>
            <td><StatusBadge status={status.sync_state} /></td>
            <td><span className='remove-connector' onClick={() => onDelete(id)}>Remove</span></td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ConnectorsTable;
