import React, { useState, useMemo } from 'react';
import { del } from '../../request';
import Button from '../Button/Button';
import ErrorMessage from '../ErrorMessage';
import { PlusIcon } from '../icons-set';
import './Connectors.css'

import { LoadingIndicator } from '../LoadingIndicator';
import MainLayout from '../MainLayout/MainLayout';
import ConnectorsTable from './ConnectorsTable';
import getConnectorsTableData from './getConnectorsTableData';

export function Connectors({ services, connectors, token, updateConnectors }) {
  const connectorsData = useMemo(() => getConnectorsTableData(connectors, services), [connectors, services]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const createDeleteFlow = deleteFn => (...args) => {
    setIsLoading(true);
    deleteFn(...args)
      .then(() => {
        updateConnectors(true);
        setIsLoading(false);
      }, setError);
  };

  const deleteConnector = createDeleteFlow(id => del(`/_/connectors/${id}`, token));
  const deleteAllConnectors = createDeleteFlow(() =>
    Promise.all(connectorsData.map(c => del(`/_/connectors/${c.id}`, token))));

  if (error)
    return <ErrorMessage error={error} />;
  
  if (isLoading)
    return <LoadingIndicator/>;

  return (
    <MainLayout
      actions={<Button type='primary' href='/services'><PlusIcon />Add Connector</Button>}
    >
      <ConnectorsTable
        connectors={connectorsData}
        onDelete={deleteConnector}
      />
      {!!connectorsData.length &&
        <Button
          type='secondary'
          onClick={deleteAllConnectors}
          className='delete-all'
        >
          Remove All Connectors
        </Button>
      }
    </MainLayout>
  );
}
