export const loginUser = async function (credentials) {
  return fetch('/_/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
  })
  .then(data => data.json())
}

export const checkAuth = async (token) => {
  if (!token)
    return false
  try{
    const response = await fetch("/_/", {
      method: 'GET',
      headers: {
        'Authorization' : 'Bearer ' + token
      }
    });
    console.log(response)
    return response.status === 200
  } catch(err) {
    return false
  }
}

export const get = async (path, token) => {
  const response = await fetch(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer ' + token
    }
  });
  if(response.status === 200)
    return await response.json();
    
  throw new Error(await response.text());
}

export const del = async (path, token) => {
  const response = await fetch(path, {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Bearer ' + token,
      'Content-type': 'application/json'
    }
  });
  if(response.status === 200)
    return await response.json();
    
  throw new Error(await response.text());
}
  
export const post = async (path, data, token) => {
    const response = await fetch(path, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache', 
      credentials: 'same-origin',
      headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization' : 'Bearer ' + token
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    });
    if (response.status === 200) 
        return await response.json();
    
    throw Error(await response.text());
}

export const handleError = (e) => {
  if (e instanceof Error){
    if (e.message.includes('Proxy error')){
      return ('Please ensure that server.js is running on localhost:5000');
    }
    if (e.message.includes('Invalid authorization credentials')) {
      return ('Please update API key and secret at server.js');
    }
  }
  return ("Unhandled error: " + e.message);
}