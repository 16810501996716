import React from 'react';
import './NoConnectors.css'

const NoConnectors = () => (
  <div className='no-connectors'>
    <h3 className='title'>No Connectors Yet!</h3>
    <p className='description'>Add a connector to get started.</p>
  </div>
);

export default NoConnectors;
