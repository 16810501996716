import React from 'react';
import './MainLayout.css';
import TabsRow from './TabsRow';
import { LogoIcon, ServicesIcon, ConnectorsIcon } from '../icons-set'

const MENU_ITEMS = [
  { Icon: ServicesIcon, title: 'Add Connector', link: '/services' },
  { Icon: ConnectorsIcon, title: 'Connectors List', link: '/connectors' },
];

const MainLayout = ({ children, actions }) => (
  <div className='main'>
    <div className='header'>
      <div className='content'>
        <div className='logo-row'>
          <div className='logo'>
            <LogoIcon />
            Vandelay
          </div>
          <div className='actions'>{actions}</div>
        </div>
        <TabsRow className='tabs-row-position' tabs={MENU_ITEMS} />
      </div>
    </div>
    <div className='content'>
      {children}
    </div>
  </div>
);

export default MainLayout;
