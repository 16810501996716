export const ConnectorsIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 2C0 0.90625 0.875 0 2 0H14C15.0938 0 16 0.90625 16 2V12C16 13.125 15.0938 14 14 14H2C0.875 14 0 13.125 0 12V2ZM6 4.25H14.5V2C14.5 1.75 14.25 1.5 14 1.5H6V4.25ZM4.5 1.5H2C1.71875 1.5 1.5 1.75 1.5 2V4.25H4.5V1.5ZM1.5 5.75V8.25H4.5V5.75H1.5ZM1.5 9.75V12C1.5 12.2812 1.71875 12.5 2 12.5H4.5V9.75H1.5ZM6 12.5H14C14.25 12.5 14.5 12.2812 14.5 12V9.75H6V12.5ZM14.5 8.25V5.75H6V8.25H14.5Z" fill="currentColor"/>
  </svg>
);

export const ServicesIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 0C5.3125 0 6 0.6875 6 1.5V4.5C6 5.34375 5.3125 6 4.5 6H1.5C0.65625 6 0 5.34375 0 4.5V1.5C0 0.6875 0.65625 0 1.5 0H4.5ZM4.5 1.5H1.5V4.5H4.5V1.5ZM4.5 8C5.3125 8 6 8.6875 6 9.5V12.5C6 13.3438 5.3125 14 4.5 14H1.5C0.65625 14 0 13.3438 0 12.5V9.5C0 8.6875 0.65625 8 1.5 8H4.5ZM4.5 9.5H1.5V12.5H4.5V9.5ZM8 1.5C8 0.6875 8.65625 0 9.5 0H12.5C13.3125 0 14 0.6875 14 1.5V4.5C14 5.34375 13.3125 6 12.5 6H9.5C8.65625 6 8 5.34375 8 4.5V1.5ZM9.5 4.5H12.5V1.5H9.5V4.5ZM11 7.5C11.4062 7.5 11.75 7.84375 11.75 8.25V10.25H13.75C14.1562 10.25 14.5 10.5938 14.5 11C14.5 11.4375 14.1562 11.75 13.75 11.75H11.75V13.75C11.75 14.1875 11.4062 14.5 11 14.5C10.5625 14.5 10.25 14.1875 10.25 13.75V11.75H8.25C7.8125 11.75 7.5 11.4375 7.5 11C7.5 10.5938 7.8125 10.25 8.25 10.25H10.25V8.25C10.25 7.84375 10.5625 7.5 11 7.5Z" fill="currentColor"/>
  </svg>
);

export const LogoIcon = () => (
  <svg width="40" height="37" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38.1699 25.3169C35.2076 20.3434 29.283 10.3965 26.3207 5.42297C23.6337 0.922543 19.6753 -0.189779 16.3722 2.06044C19.3345 0.960899 21.7725 2.11158 22.9259 4.31065C25.5736 9.32249 29.1651 18.8092 31.3409 25.3808C33.3857 31.5689 32.324 35.673 26.1241 36.2228H31.6948C38.8253 36.21 41.7351 31.3004 38.1699 25.3169Z" fill="black"/>
    <path d="M13.3579 5.40955C10.4087 10.383 4.48412 20.3428 1.52184 25.3163C-1.15208 29.8167 -0.169026 33.7034 3.50106 35.3528C1.03686 33.4222 0.853356 30.8012 2.22964 28.7172C5.37543 23.9866 12.0471 16.2259 16.8182 11.1118C21.3272 6.30453 25.5216 5.14106 29.113 10.0762L26.3211 5.38398C22.7559 -0.586758 16.9231 -0.586758 13.3579 5.40955Z" fill="#FF40FF"/>
    <path d="M7.99815 36.2478C13.9227 36.2606 25.7587 36.2606 31.6702 36.2734C37.018 36.2734 40.0065 33.5117 39.6526 29.5994C39.1546 32.6423 36.9001 34.0999 34.3703 33.972C28.5769 33.6907 18.3137 31.9392 11.3668 30.4688C4.82615 29.088 1.69347 26.1346 4.31496 20.6497L1.52307 25.3419C-2.04216 31.3382 0.867697 36.235 7.99815 36.2478Z" fill="#890058"/>
  </svg>
);

export const PlusIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6875 5.75C11.6875 6.13281 11.3867 6.40625 11.0312 6.40625H6.65625V10.7812C6.65625 11.1641 6.35547 11.4648 6 11.4648C5.61719 11.4648 5.34375 11.1641 5.34375 10.7812V6.40625H0.96875C0.585938 6.40625 0.3125 6.13281 0.3125 5.77734C0.3125 5.39453 0.585938 5.09375 0.96875 5.09375H5.34375V0.71875C5.34375 0.363281 5.61719 0.0898438 6 0.0898438C6.35547 0.0898438 6.65625 0.363281 6.65625 0.71875V5.09375H11.0312C11.3867 5.09375 11.6875 5.39453 11.6875 5.75Z" fill="currentColor"/>
  </svg>
);
