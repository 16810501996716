import React from 'react';
import cn from 'classnames';
import './StatusBadge.css';

const getColor = status => {
  switch (status) {
    case 'active':
      return 'green';
    case 'broken':
      return 'red';
    default:
      return 'grey';
  }
}

const StatusBadge = ({ status }) => (
  <div className={cn('badge', getColor(status))}>{status}</div>
);

export default StatusBadge;
